.filter-wrapper{
	display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  padding: 0px 13px;
	margin-bottom: 20px;
	margin-top: 20px;
}
.btn.v-center{
    margin-top: 3px;
}
.filter{
  display: inline-flex;
  justify-content: flex-start;
  padding-left: 30px;

	&:first-child{
	  padding-left: 0px;
	  margin-left: 0px;
	}		
  &.right{
    margin: auto;
    margin-right: 0px;
  }
}

.filter-start{
	justify-content: flex-start;
	.filter{
    	padding-left: 30px;
    	&:last-child{
			margin: initial;
		}	
		&:first-child{
			padding-left: 0px;
		    margin-left: 0px;
		}	
	}
 }

.filter input,.filter select,.filter span.select2{
	margin-left: 10px;
	margin-bottom: 10px;
	text-align: center;
}

.filter.filter-left input,.filter.filter-left select,.filter.filter-left span.select2{
	margin-left: 10px;
	margin-bottom: 10px;
	text-align: left;
}

.filter span.select2-selection{
    min-width: 120px;
    padding: 0px 15px;
}
.filter.filter-from-to input{
	max-width: 100px;
}
.filter.filter-from-to input:nth-child(3){
	margin-left: 3px;
}

#filter-length{
	min-width: 70px;
	max-width: 70px;
}
#filter-search{
	min-width: 200px;
	max-width: 200px;
}

#filter-status{
	min-width: 100px;
	max-width: 100px;
}

.filter label{
	padding-top: 10px;
}

.filter.filter-checked{
    align-content: center;
    align-items: center;
    margin-bottom: 10px;
    label{
      margin-right:10px;
    }   
}

@media (min-width: 1024px)
{
	.modal.modal-large .modal-dialog
	{
		width: 900px;
		margin: 30px auto;
	}

	.modal.modal-large .modal-content
	{
		max-width: 900px;
	}

	.modal.modal-large .modal-dialog .modal-content
	{
		width: 100%;
	}
}

#email-modal{
	overflow-y: scroll;
	max-height: 600px;
}

.img-fit{
	max-width: 100%;
	max-height: 400px;
}
