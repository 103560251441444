/* General styles for the modal */

/* 
Styles for the html/body for special modal where we want 3d effects
Note that we need a container wrapping all content on the page for the 
perspective effects (not including the modals and the overlay).
*/

//Body styles to allow verticall scrolling
.@{modal-prefix}-open {
	overflow: hidden;
}

.@{modal-prefix}-perspective,
.@{modal-prefix}-perspective body {
	height: 100%;
	overflow: hidden;
}

.@{modal-prefix}-perspective body  {
	background: #222;
	-webkit-perspective: 600px;
	-moz-perspective: 600px;
	perspective: 600px;
}

.@{modal-prefix}-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: @modal-zindex;
	visibility: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
}

.@{modal-prefix}-show {
	visibility: visible;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: @modal-zindex + 1;
}

.@{modal-prefix}-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	visibility: hidden;
	top: 0;
	left: 0;
	z-index: @modal-zindex;
	opacity: 0;
	background: @modal-overlay-bg;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.@{modal-prefix}-show ~ .@{modal-prefix}-overlay {
	opacity: 1;
	visibility: visible;
}

/* Content styles */
.@{modal-prefix}-content {
	position: relative;
	border-radius: @modal-border-radius;
	margin: 30px auto;
	max-width: 630px;
	min-width: 320px;
}

// Measure scrollbar width for padding body during modal show/hide
.@{modal-prefix}-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}