/*------------------------------------------------------------------
  [dataTables]
*/
table.dataTable{
	margin: 0 !important;
}

div.dataTables_wrapper{

	div.dataTables_info{
		color: lighten(@text-color, 13%);
		padding-top: 9px;
	}

	div.dataTables_paginate{
		
		margin-top: 10px;
		ul.pagination{
			margin: 0;
		}
	}

	div.dataTables_filter{
	
		input{
			padding-top: 6px;
			padding-bottom: 6px;
		}
	}

	div.dataTables_length label, div.dataTables_filter label{
		margin-bottom: 0;
		font-size: 13px;
		color: lighten(@text-color, 13%);
	}

	div.dataTables_length label{

		select{
			padding: 2px;
		}
	}
}

.be-datatable-header{
  border-bottom: 1px solid @input-border;
  margin: 2px 0 0;
  padding: 15px 10px 22px;

  .dt-buttons{

		.btn{

			embed{
				outline: none;
			}
		}
  }
}

.be-datatable-footer{
	margin: 0;
	padding: 15px 3px 13px;
	background-color: darken(@light-color, 3%);
	border-top: 1px solid @input-border;
}
th{
	padding-left: 20px !important;
}


table.dataTable.table-condensed .sorting:after,
table.dataTable.table-condensed .sorting_asc:after,
table.dataTable.table-condensed .sorting_desc:after {
  position: relative;
  display: -webkit-inline-box;
  padding-left: 5px;
  top: 2px;
  left: 1px;
}
