/*------------------------------------------------------------------
	[Main wrapper]
*/
.be-wrapper{
	min-height: 100%;
	padding-top: @top-header-height + 20px;

	@media @phone {
		overflow-x: hidden;
	}
}