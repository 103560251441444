#clients-datatable_filter,#clients-datatable_length{
	display: none;
}

#clients-datatable th{
  padding-left: 0px !important;
  padding-right: 0px;
  &:nth-child(9){
    min-width: 80px;
  }
}