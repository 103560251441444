#orders-datatable_filter,#orders-datatable_length{
	display: none;

}

#orders-filter-wrapper{
	#filter-client{
		max-width: 144px;
		min-width: 144px;
	}
	#filter-date{
		max-width: 175px;
		min-width: 175px;
	}
	 #filter-bill, #filter-budget{
		max-width: 100px;
		min-width: 100px;
	}
}

.bill-message{
	position: absolute;
	top: 0px;
	background-color: #36b057;
	left: 0px;
	width: 100%;
	color: #fff;
	padding: 5px;
	text-align: center;
	display: none;
}

#bill-name{
	margin-bottom: 0px;
	text-align: center;
	width: 98px;
}
#file-attach-name{
	margin-bottom: 0px;
	text-align: center;
	width: 98px;
}