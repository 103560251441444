#DataTables_Table_0_filter,#DataTables_Table_0_length{
  display: none;
}

.budgets-filter{
  display: flex;
  
  div:nth-child(1){
    margin: auto;
  }
  
  .filter-wrapper{
    margin-left: 10px;
  }

  .filter:nth-child(1){
    margin-left: 10px;
    margin-right: 0px;

    label{
      padding-left: 10px;
    }
  }
}